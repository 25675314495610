<template>
	<main id="post-dicom-service-install">
		<div class="inner">
			<img src="@assets/logo-asteris2.png" />

			<h1>DICOM Service Installed</h1>
			<br />
			<p style="text-align:left">
				Congratulations! Your Asteris Keystone DICOM service is installed and ready to use. You can send images from
				your imaging modality directly into this service.
			</p>
			<p style="text-align:left; margin-top:10px">
				Please contact your modality vendor if you need assistance to configure your modality. You will need to utilize
				the following connection details:
			</p>
			<br />
			<table v-if="info">
				<tr>
					<td>Host Name:</td>
					<td>{{ info.hostName }}</td>
				</tr>
				<tr>
					<td>IP Address:</td>
					<td>{{ info.ipAddress }}</td>
				</tr>
				<tr>
					<td>AE Title:</td>
					<td>{{ info.aeTitle }}</td>
				</tr>
				<tr>
					<td>Port:</td>
					<td>{{ info.dicomPort }}</td>
				</tr>
				<tr>
					<td>Folder:</td>
					<td>{{ info.dicomFolder }}</td>
				</tr>
			</table>

			<div v-else style="color:royalblue">
				<i>Fetching your DICOM Service information..</i>
			</div>

			<br />
			<p v-if="isAuthenticated">
				View and change your service
				<a @click="openDicomServiceDlg()">HERE</a>
			</p>
		</div>
	</main>
</template>

<script>
import { dicomServiceData } from '@services/dicomServiceData'
import { openDicomServiceDlg } from '@/dialogs/DicomServiceDlg.vue'

export default {
	components: {},
	data() {
		return {
			info: null,
		}
	},
	computed: {
		isAuthenticated() {
			return this.$store.getters.isAuthenticated
		},
	},
	created() {
		setTimeout(async () => {
			let token = this.$route.params.token
			if (token) {
				this.info = await dicomServiceData.getRegInfo(token)
			}
		}, 4000)
	},
	methods: {
		openDicomServiceDlg,
	},
}
</script>

<style lang="scss">
#post-dicom-service-install {
	font-size: 1.2em;
	display: flex;
	justify-content: center;
	padding: 20px;
	flex-direction: column;
	align-items: center;
	background: whitesmoke;
	color: #333;

	.inner {
		text-align: center;
		max-width: 800px;
		line-height: 1.6;
	}

	a {
		color: blue;
	}

	table {
		margin-left: auto;
		margin-right: auto;

		td:first-of-type {
			text-align: right;
		}

		td:last-of-type {
			color: royalblue;
		}
	}

	img {
		width: 300px;
	}
}
</style>
